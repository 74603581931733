import { ButtonHTMLAttributes, SVGAttributes, useState } from 'react';

import ExportedImage from 'next-image-export-optimizer';
import Link from 'next/link';
import { useRouter } from 'next/router';

import {
  ArrowIconWithDir,
  CheckIcon,
  DiscordIconNew,
  EmailIconNew,
  GitHubIcon,
  Logo,
  OpenNewTabIcon,
  RedditIconNew,
  TelegramIconNew,
  TwitterIconNew,
  YoutubeIconNew,
} from '@/components/icons/ElementIcons';
import { FooterDropdown } from '@/components/shared/lp/FooterDropdown';
import NewsletterSubscriptionForm from '@/components/shared/lp/NewsletterSubscriptionForm';

import fileData from '@/data/docs/_fileData.json';
import { siteData } from '@/data/site';
import { humanizeTime } from '@/shared-functions/timeAgo';

import footerArrow from '/public/img/atomicdex/footer-arrow.svg';

type FooterItem = {
  href: string;
  text: string;
  arrow: string;
  icon?: React.ReactNode;
  shortText: string;
};

const FooterDropdownData: Array<{
  headerNo: number;
  header: string;
  footerItems: FooterItem[];
}> = [
  {
    headerNo: 1,
    header: 'Social Media',
    footerItems: [
      {
        href: siteData.social.twitter,
        text: 'X.com',
        arrow: 'no',
        shortText: 'twitter',
        icon: <TwitterIconNew className='mr-3 h-auto w-[19px]' />,
      },
      {
        href: siteData.social.discord,
        text: 'Discord',
        arrow: 'no',
        icon: <DiscordIconNew className='mr-3 h-auto w-[19px]' />,
        shortText: 'discord',
      },
      {
        href: siteData.social.reddit,
        text: 'Reddit',
        arrow: 'no',
        icon: <RedditIconNew className='mr-3 h-auto w-[19px]' />,
        shortText: 'reddit',
      },
      {
        href: siteData.social.telegram,
        text: 'Telegram',
        arrow: 'no',
        icon: <TelegramIconNew className='mr-3 h-auto w-[19px]' />,
        shortText: 'telegram',
      },
      {
        href: siteData.social.youtube,
        text: 'Youtube',
        arrow: 'no',
        icon: <YoutubeIconNew className='mr-3 h-auto w-[19px]' />,
        shortText: 'youtube',
      },
      {
        href: `mailto:${siteData.helloEmail}`,
        text: 'Email',
        arrow: 'no',
        icon: <EmailIconNew className='mr-3 h-auto w-[19px]' />,
        shortText: 'email',
      },
    ],
  },
  {
    headerNo: 2,
    header: 'Products',
    footerItems: [
      {
        href: siteData.webDEXlink,
        text: 'Komodo Web Wallet',
        arrow: 'yes',
        shortText: 'webWallet',
      },
      {
        href: siteData.downloadsPage,
        text: 'Downloads',
        arrow: 'no',
        shortText: 'downloads',
      },
    ],
  },
  {
    headerNo: 3,
    header: 'Developers',
    footerItems: [
      {
        href: 'https://github.com/KomodoPlatform/komodo-defi-framework',
        text: 'Komodo DeFi Framework',
        arrow: 'yes',
        shortText: 'komodoDefiFramework',
      },
      {
        href: 'https://github.com/KomodoPlatform/komodo-wallet-desktop',
        text: 'Desktop GUI repo',
        arrow: 'yes',
        shortText: 'desktopGuiRepo',
      },
      {
        href: 'https://github.com/KomodoPlatform/komodo',
        text: 'Komodo',
        arrow: 'yes',
        shortText: 'komodo',
      },
    ],
  },
  {
    headerNo: 4,
    header: 'Help',
    footerItems: [
      {
        href: '/en/privacy/',
        text: 'Privacy Policy',
        arrow: 'no',
        shortText: 'privacyPolicy',
      },
      {
        href: '/en/tos/',
        text: 'Disclaimer',
        arrow: 'no',
        shortText: 'disclaimer',
      },
      {
        href: '/en/contact/',
        text: 'Contact Us',
        arrow: 'no',
        shortText: 'contactUs',
      },
    ],
  },
];

export const LpFooter = () => {
  const [selected, setSelected] = useState(null);

  const router = useRouter();
  return (
    <footer
      className={`container relative mx-auto flex flex-col items-center overflow-hidden pt-[60px] font-sans text-white ${router.asPath.includes('/flyer/') ? 'hidden' : 'lg:grid lg:grid-cols-24 lg:gap-0'}`}
    >
      <div className='hidden lg:col-span-1 lg:block xl:col-span-2'></div>
      <div className='relative hidden justify-between px-4 xs:px-8 md:col-span-24 lg:col-span-22 lg:block lg:px-0 xl:col-span-20'>
        <div className='relative flex items-center justify-between md:grid md:grid-cols-24 md:justify-center md:gap-0 lg:justify-between'>
          <div className='text-white lg:col-span-6 xlm:col-span-7'>
            <div className='w-[232px]'>
              <div className='flex w-full justify-between md:w-auto'>
                <Link href='/en/' aria-label='Komodo logo' className=''>
                  <Logo />
                </Link>
              </div>
            </div>
          </div>
          <div className='lg:col-span-4 xlm:col-span-4'>
            <div className='w-[170px]'>
              <h3 className='leading-1 font-heading text-base font-semibold text-br-footer-gray lg:text-lg'>
                Social Media
              </h3>
            </div>
          </div>
          <div className='lg:col-span-5 xlm:col-span-5'>
            <div className='w-[168px]'>
              <h3 className='leading-1 font-heading text-base font-semibold text-br-footer-gray lg:text-lg'>
                Products
              </h3>
            </div>
          </div>
          <div className='lg:col-span-6 xlm:col-span-5'>
            <div className='w-[207px]'>
              <h3 className='leading-1 font-heading text-base font-semibold text-br-footer-gray lg:text-lg'>
                Developers
              </h3>
            </div>
          </div>
          <div className='lg:col-span-3 xlm:col-span-3'>
            <div className='w-[107px]'>
              <h3 className='leading-1 font-heading text-base font-semibold text-br-footer-gray lg:text-lg'>
                Help
              </h3>
            </div>
          </div>
        </div>
        <div className='relative lg:grid lg:grid-cols-24 lg:gap-0'>
          <div className='lg:col-span-6 xlm:col-span-7'>
            <div className='mt-[32px] w-[232px] border-r-[2px] border-[#3C3C3C]'>
              <h3 className='text-start font-heading font-semibold lg:text-base'>
                Subscribe to newsletter
              </h3>
              <Link
                href='https://dashboard.mailerlite.com/forms/20792/52187545986925609/share'
                className='mb-4 text-start text-base font-normal leading-[21.83px] text-blue-500 underline lg:text-sm'
                target='_blank'
                rel='noreferrer'
              >
                Receive the latest news
                <br /> from Komodo
              </Link>
              {/* <NewsletterSubscriptionForm /> */}
            </div>
          </div>
          <div className='text-white lg:col-span-4 xlm:col-span-4'>
            <div className='w-[170px]'>
              <ul role='list' className='mt-2 space-y-[8px]'>
                {FooterDropdownData[0].footerItems.map((item, index) => (
                  <li className='flex space-x-3' key={index}>
                    <Link href={item.href} className={`desk-footer-${item.shortText}`}>
                      <div className='flex'>
                        {item.icon}
                        <span className='leading-1 text-sm font-medium lg:text-base'>
                          {item.text}
                        </span>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className='text-white lg:col-span-5 xlm:col-span-5'>
            <div className='w-[168px]'>
              <ul role='list' className='mt-2 space-y-[8px]'>
                {FooterDropdownData[1].footerItems.map((item, index) => (
                  <li className='flex space-x-3' key={index}>
                    <Link href={item.href} className={`desk-footer-${item.shortText}`}>
                      <div className='flex items-center'>
                        <span className='leading-1 text-base font-medium text-[#F4F4F4]'>
                          {item.text}
                        </span>
                        {item.arrow === 'yes' && (
                          <ExportedImage
                            src={footerArrow}
                            className='ml-1 h-auto w-[10px]'
                            alt='footer-arrow'
                          />
                        )}
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className='text-white lg:col-span-6 xlm:col-span-5'>
            <div className='w-[207px]'>
              <ul role='list' className='mt-2 space-y-[8px]'>
                {FooterDropdownData[2].footerItems.map((item, index) => (
                  <li key={index} className='flex space-x-3'>
                    <Link href={item.href} target='_blank' rel='noreferrer'>
                      <div className='flex flex-row'>
                        <span className='leading-1 text-sm font-medium text-[#F4F4F4] lg:text-base'>
                          {item.text}
                        </span>
                        <ExportedImage
                          src={footerArrow}
                          className='ml-1 h-auto w-[10px]'
                          alt='footer-arrow'
                        />
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className='text-white lg:col-span-3 xlm:col-span-3'>
            <div className='w-[170px]'>
              <ul role='list' className='mt-2 space-y-[8px]'>
                {FooterDropdownData[3].footerItems.map((item, index) => (
                  <li key={index} className='flex space-x-3'>
                    <Link href={item.href}>
                      <span className='leading-1 text-sm font-medium underline lg:text-base'>
                        {item.text}
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className='footer-lg-gray relative flex items-center justify-center pb-[60px]'>
          <p className='footer-komodo pt-12 text-left font-heading text-sm font-normal text-white lg:text-base'>
            &copy; 2016 - {new Date().getFullYear()} by&nbsp;
            <Link href={siteData.komodoPlatformHome} target='_blank' rel='noreferrer'>
              <span className='leading-1 footer-komodo rounded-none border-b pb-0.5 pt-12 text-left font-heading text-sm font-semibold text-white lg:text-base'>
                Komodo Platform
              </span>
            </Link>
          </p>
        </div>
      </div>
      <div className='container mx-auto block overflow-hidden py-9 text-white lg:hidden'>
        <div className='relative mx-auto flex max-w-[280px] flex-wrap items-center justify-between xs:max-w-[343px]'>
          <Link href={siteData.social.twitter} className='mobi-footer-twitter'>
            <TwitterIconNew className='h-auto w-[35.26px]' />
          </Link>

          <Link href={siteData.social.discord} className='mobi-footer-discord'>
            <DiscordIconNew className='h-auto w-[35.26px]' />
          </Link>

          <Link href={siteData.social.reddit} className='mobi-footer-reddit'>
            <RedditIconNew className='h-auto w-[35.26px]' />
          </Link>

          <Link href={siteData.social.telegram} className='mobi-footer-telegram'>
            <TelegramIconNew className='h-auto w-[35.26px]' />
          </Link>

          <Link href={siteData.social.youtube} className='mobi-footer-youtube'>
            <YoutubeIconNew className='h-auto w-[35.26px]' />
          </Link>

          <Link href={`mailto:${siteData.helloEmail}`} className='mobi-footer-email'>
            <EmailIconNew className='h-auto w-[35.26px]' />
          </Link>
        </div>
        <div className='mx-auto flex max-w-[280px] flex-wrap justify-center pt-6 xs:w-full xs:max-w-[343px]'>
          <div className='w-full'>
            {FooterDropdownData.map((item, index) => (
              <FooterDropdown
                key={index}
                headerNo={item.headerNo}
                header={item.header}
                footerItems={item.footerItems}
                selected={selected}
                setSelected={setSelected}
              />
            ))}
          </div>
        </div>

        <div className='relative mx-auto flex max-w-[280px] flex-wrap justify-center py-8 xs:w-full xs:max-w-[300px]'>
          <Link href={siteData.webDEXlink} className='mobi-footer-web'>
            <div className='flex'>
              <span className='text-base font-medium'>Komodo Wallet (web)</span>
              <ExportedImage
                src={footerArrow}
                className='ml-1 h-auto w-[10px]'
                alt='footer-arrow'
              />
            </div>
          </Link>
        </div>
        <div className='relative mx-auto flex w-[280px] flex-col items-center justify-center pt-6 xs:w-[343px]'>
          <h3 className='text-center text-[24px] font-bold'>Subscribe to newsletter</h3>
          <Link
            href='https://dashboard.mailerlite.com/forms/20792/52187545986925609/share'
            className='mx-auto text-center text-sm font-normal text-blue-500 underline'
            target='_blank'
            rel='noreferrer'
          >
            Receive the latest news from Komodo
          </Link>
          {/* <NewsletterSubscriptionForm /> */}
        </div>
        <div className='relative mx-auto flex w-[280px] flex-row items-end justify-center pt-12 xs:w-[343px]'>
          <Link href='/en/' aria-label='Komodo logo' className=''>
            <Logo />
          </Link>
        </div>
        <div className='footer-sm-gray relative mx-auto flex flex-col justify-between pt-6'>
          <p className='footer-komodo pt-8 text-center text-sm font-medium sm:text-center xl:w-full'>
            © 2016 - {new Date().getFullYear()} by&nbsp;
            <Link href={siteData.komodoPlatformHome} target='_blank' rel='noreferrer'>
              <span className='footer-komodo rounded-none border-b pb-0.5 pt-6 text-center text-sm font-medium'>
                Komodo Platform
              </span>
            </Link>
          </p>
          {/* <p className='pt-8 text-sm font-medium text-center xl:text-base footer-komodo'>
            <Link href='/en/privacy'>
              <span className='pt-6 text-sm font-medium text-center lg:text-base footer-komodo rounded-none border-b pb-0.5'>
                Privacy Policy
              </span>
            </Link>
          </p>
          <p className='pt-8 text-sm font-medium text-center xl:text-base footer-komodo'>
            <Link href='/en/tos/'>
              <span className='pt-6 text-sm font-medium text-center xl:text-base footer-komodo rounded-none border-b pb-0.5'>
                Disclaimer
              </span>
            </Link>
          </p> */}
        </div>
      </div>
      <div className='hidden lg:col-span-1 lg:block xl:col-span-2'></div>
    </footer>
  );
};
