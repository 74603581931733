import { Fragment, useEffect, useRef, useState } from 'react';

import ExportedImage from 'next-image-export-optimizer';
import Link from 'next/link';

import { XCircleIcon } from '@heroicons/react/24/outline';
// import "@reach/dialog/styles.css";
import { ArrowUpRightIcon } from '@heroicons/react/24/solid';
import { Dialog, DialogContent, DialogOverlay } from '@reach/dialog';
import { constants } from 'constants.mjs';

import { User } from '@/components/mdx/types';

import { inter } from '@/styles/fonts';

import { humanizeTime } from '@/shared-functions/timeAgo';

export const Contributors = ({ route, lastContributor, allContributors, fileData_route }: any) => {
  let [isContributorsOpen, setIsContributorsOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const closeModalIfClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        closeModal();
      }
    };
    document.addEventListener('mousedown', closeModalIfClickOutside);

    return () => {
      document.removeEventListener('mousedown', closeModalIfClickOutside);
    };
  }, []);

  function closeModal() {
    setIsContributorsOpen(false);
  }
  function openModal() {
    setIsContributorsOpen(true);
  }
  return fileData_route ? (
    <div
      className={`my-4 flex w-full flex-col items-center justify-between overflow-x-hidden sm:flex-row`}
    >
      <div className='flex flex-row items-center py-4 sm:max-w-[60%] sm:py-0'>
        <ExportedImage
          className='h-10 w-10 rounded-[50%]'
          src={`/images/docs/authors/${lastContributor.image}`}
          alt='latest contributor to this doc'
          width={40}
          height={40}
          placeholder='empty'
          priority
        />
        {fileData_route.dateModified && (
          <div className='flex flex-wrap sm:flex-row'>
            <p className='ml-2 flex flex-row items-center text-lg text-zinc-600 dark:text-zinc-400'>
              Last Edit:
            </p>

            <Link
              href={`https://github.com/${lastContributor.username}`}
              className='ml-2 break-all text-blue-300 hover:text-blue-500'
              target='_blank'
              rel='noreferrer'
            >
              <span className='underline'>{'@' + lastContributor.username}</span>
              <span className='ml-1 no-underline'>↗</span>
              {/* <ArrowUpRightIcon className="w-4 h-4 ml-1" /> */}
            </Link>

            <div className='ml-2 flex flex-row text-lg text-zinc-600 dark:text-zinc-400'>
              <time dateTime={fileData_route.dateModified}>
                {humanizeTime(fileData_route.dateModified)}
              </time>
            </div>
          </div>
        )}
      </div>
      <button
        className='flex w-full flex-row items-center justify-center rounded-lg border-2 border-sky-700 bg-slate-800 p-2 text-sm text-sky-500 hover:bg-slate-600 dark:text-blue-400 sm:w-auto sm:justify-between'
        onClick={openModal}
      >
        See Contributors
      </button>
      <Dialog isOpen={isContributorsOpen} onDismiss={closeModal}>
        <div className=''>
          <div className='fixed inset-0 bg-black bg-opacity-70' />
          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <div
                ref={ref}
                className={`w-full max-w-md transform overflow-hidden rounded-2xl bg-slate-800 p-6 text-left align-middle font-sans shadow-xl transition-all`}
              >
                <div className='flex flex-row items-center justify-between'>
                  <h3 className='text-lg font-medium leading-6 text-blue-200'>Contributors</h3>

                  <button
                    type='button'
                    className='text-blue-200 hover:text-blue-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                    onClick={closeModal}
                  >
                    <XCircleIcon className='h-8 w-8' />
                  </button>
                </div>
                <div className='mt-2'>
                  <p className='text-sm text-gray-200'>
                    Thanks to everyone who has contributed to this doc!
                  </p>
                </div>

                <div className='mt-4 grid max-h-[400px] gap-y-2 overflow-y-auto overflow-x-hidden'>
                  {allContributors.map((contributor: any) => contributorDiv(contributor))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  ) : (
    <div></div>
  );
};

const contributorDiv = (contributor: User) => {
  return (
    <div key={contributor.username} className='my-1 flex flex-row items-center justify-between'>
      <div className='flex flex-row items-center'>
        <ExportedImage
          className='h-10 w-10 rounded-[50%]'
          src={`/images/docs/authors/${contributor.image}`}
          alt='latest contributor to this doc'
          width={40}
          height={40}
          placeholder='empty'
          priority
        />
        <p className='ml-4 flex flex-row items-center text-lg text-zinc-600 dark:text-zinc-400'>
          <Link
            href={`https://github.com/${contributor.username}`}
            className='ml-1 flex flex-row items-center text-blue-300 underline hover:text-blue-500'
            target='_blank'
            rel='noreferrer'
          >
            @{contributor.username}
            <ArrowUpRightIcon className='ml-1 h-4 w-4' />
          </Link>
        </p>
      </div>
      {/* <div className="flex flex-row items-center gap-2">
        {contributor.socials?.twitter && (
          <Link
            href={`https://twitter.com/${contributor.socials.twitter}`}
            target="_blank"
            rel="noreferrer"
          >  <img src={twitter.src} alt="twitter icon"   className="w-6 h-6"/>
          </Link>)}
          { contributor.socials?.linkedin && (  <Link
            href={`${contributor.socials.linkedin}`}         
            target="_blank"
            rel="noreferrer"
          >  <img src={linkedin.src} alt="linkedin icon"  className="w-6 h-6 ml-4"/>
          </Link>)}
      </div> */}
    </div>
  );
};
