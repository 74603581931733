import React, { useEffect, useState } from 'react';

import ExportedImage from 'next-image-export-optimizer';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { AnimatePresence, motion } from 'framer-motion';
import platform from 'platform';

import komodoWalletDownloads from '@/data/KomodoWalletDownloads.json';
import { siteData } from '@/data/site';
import gradientLinkArrow from '@/public/images/lp/svgs/gradient-link-arrow.svg';

function getDownloadURL(data: any, osFamily: string | undefined) {
  // Mobile
  if (osFamily == 'Android') return data.android;
  if (osFamily == 'iOS') return data.ios;
  // Desktop
  if (osFamily == 'Windows') return data.windows;
  if (osFamily == 'OS X') return data.macos;
  if (osFamily == 'Linux' || 'Ubuntu' || 'Debian') return data.linux;
  return data.default;
}

export function KomodoWalletBtnNav() {
  let router = useRouter();

  let isHomeLp = router.asPath === '/en/' || router.asPath === '/en';

  const [isScrolledPastHero, setIsScrolledPastHero] = useState(!isHomeLp);
  const [downloadURL, setDownloadURL] = useState(siteData.downloadURL);
  // Debounce function
  const debounce = <F extends (...args: any[]) => any>(func: F, wait: number) => {
    let timeout: ReturnType<typeof setTimeout>;
    return (...args: Parameters<F>) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  };

  useEffect(() => {
    const handleScroll = () => {
      const firstSection = document.getElementById('hero-section');
      if (firstSection && isHomeLp) {
        const sectionBottom = firstSection.offsetTop + firstSection.offsetHeight;
        setIsScrolledPastHero(window.scrollY > sectionBottom);
      } else {
        setIsScrolledPastHero(true);
      }
    };
    handleScroll();
    window.addEventListener('scroll', debounce(handleScroll, 100));
    return () => window.removeEventListener('scroll', handleScroll);
  }, [router, isHomeLp]);
  useEffect(() => {
    //https://github.com/bestiejs/platform.js/blob/master/doc/README.md#platformosfamily
    let osFamily = platform?.os?.family;
    setDownloadURL(getDownloadURL(komodoWalletDownloads, osFamily));
  }, []);

  return (
    <Link
      href={!isScrolledPastHero ? downloadURL : siteData.webDEXlink}
      className='desk-nav-launch_app relative ml-auto'
    >
      <motion.button
        type='button'
        className={`relative flex flex-row items-center overflow-hidden rounded-[14px] border border-none px-3 py-2 font-sans text-base font-bold shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${
          isScrolledPastHero
            ? 'hero-btn justify-left text-white'
            : 'justify-center bg-white text-black'
        } w-[160px]`}
        whileHover={{
          y: -4,
          boxShadow: '0 4px 15px rgba(84, 104, 230, 0.6)',
          transition: { duration: 0.2 },
        }}
        whileTap={{ scale: 0.95 }}
      >
        <AnimatePresence mode='wait'>
          <motion.span
            key={isScrolledPastHero ? 'download' : 'launch'}
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -20, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className='left-1/2 -translate-x-1/2 transform'
          >
            {!isScrolledPastHero ? 'Download Wallet' : 'Launch Wallet'}
          </motion.span>
        </AnimatePresence>

        <AnimatePresence>
          {isScrolledPastHero && (
            <motion.div
              initial={{ opacity: 0, x: -10 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 10 }}
              transition={{ duration: 0.2 }}
              className='absolute right-3'
            >
              <ExportedImage
                src={gradientLinkArrow}
                className='h-[19px] w-auto'
                width={16}
                height={17}
                alt='link'
              />
            </motion.div>
          )}
        </AnimatePresence>
      </motion.button>
    </Link>
  );
}
